<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="发送状态">
        <el-select
          v-model="form.status"
          size="small"
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option label="待执行" value="2"> </el-option>
          <el-option label="已执行" value="3"> </el-option>
          <el-option label="已取消" value="4"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建人">
        <el-input
          v-model="form.createUserName"
          size="small"
          style="width: 160px"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="form.time1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 350px"
          size="small"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发送时间">
        <el-date-picker
          v-model="form.time2"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 340px"
          size="small"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "PageHeader",
  components: {},
  data() {
    return {
      form: {},
      fold: false,
    };
  },
  methods: {
    onSubmit() {
      const data = { ...this.form };
      if (data.time1) {
        data.createTimeStart = moment(data.time1[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        data.createTimeEnd = moment(data.time1[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (data.time2) {
        data.sendTimeStart = moment(data.time2[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        data.sendTimeEnd = moment(data.time2[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      delete data.time1;
      delete data.time2;
      this.$emit("search", data);
    },
    reset() {
      this.form = {};
      this.$emit("search", this.form);
    },
    checkFold() {
      this.fold = !this.fold;
    },
  },
};
</script>
<style lang="less" scoped>
</style>
