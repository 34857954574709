<template>
  <div v-loading="loading" class="bigBox">
    <div class="top-title">
      <span>{{ data.statusName }}</span>
      <span>
        <el-button size="small" @click="back">返回</el-button>
        <el-button
          v-if="!data.isDelaySend == 0 && (data.status == 2 || data.status == 1)"
          size="small"
          type="primary"
          @click="cancel"
          >取消发送</el-button
        >
      </span>
    </div>
    <div class="content">
      <div class="title">基本信息</div>
      <div class="tabel">
        <div class="box">
          <div class="name">序号</div>
          <div class="val">{{ row.num }}</div>
        </div>
        <div class="box">
          <div class="name">创建人</div>
          <div class="val">{{ data.createUserName }}</div>
        </div>
        <div class="box">
          <div class="name">创建时间</div>
          <div class="val">{{ data.createTime }}</div>
        </div>
        <div class="box">
          <div class="name">发送时间</div>
          <div class="val">{{ data.sendTime }}</div>
        </div>
      </div>
      <div class="tabel" style="border-top: none">
        <div class="box">
          <div class="name">发送主题</div>
          <div class="val">
            {{ type == 'send' ? '--' : data.planTitle }}
          </div>
        </div>
        <div class="box">
          <div class="name">发送方式</div>
          <div class="val">{{ data.isDelaySendName }}</div>
        </div>
        <div class="box">
          <div class="name">发送人群</div>
          <div class="val">{{ data.receiverRangeKeyName }}</div>
        </div>
        <div class="box">
          <div class="name"></div>
          <div class="val"></div>
        </div>
      </div>
      <div class="title">消息模板</div>
      <el-table :data="[data]" border style="width: 100%" class="list">
        <el-table-column align="center" prop="templateId" label="模板ID" min-width="150">
        </el-table-column>
        <el-table-column
          prop="templateCategoryName"
          align="center"
          label="模板类型"
          min-width="150"
        >
        </el-table-column>
        <el-table-column align="center" prop="templateName" label="模板名称" min-width="150">
        </el-table-column>
        <el-table-column align="center" prop="templateContent" min-width="150" label="模板内容">
        </el-table-column>
      </el-table>

      <div class="title">发送统计</div>
      <div class="statistics">
        <div>
          <div>触发人数</div>
          <div style="color: #02a7f0">{{ data.receiverNumber }}</div>
        </div>
        <div>
          <div>发送成功</div>
          <div style="color: #70b603">
            {{ data.successNumber ? data.successNumber : 0 }}
          </div>
        </div>
        <div>
          <div>发送失败</div>
          <div style="color: #f59a23">
            {{ data.failNumber ? data.failNumber : 0 }}
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column label="序号" min-width="80">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + (form.pageNumber - 1) * form.pageSize }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="receiverId" label="客户ID" min-width="80"> </el-table-column>
        <el-table-column prop="receiverName" label="客户姓名" min-width="100"> </el-table-column>
        <el-table-column prop="phoneNumber" label="发送号码" min-width="120"> </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="templateContent"
          label="发送内容"
          min-width="200"
        >
        </el-table-column>
        <el-table-column prop="sendTime" label="实际发送时间" min-width="150"> </el-table-column>
        <el-table-column prop="statusName" label="发送结果" min-width="150">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.status == 0 ? 'red' : '' }">{{
              scope.row.statusName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="failureReason" min-width="150" label="失败原因">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? '--' : scope.row.failureReason }}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pages
        style="margin-bottom: 20px"
        :total="total"
        :current-page="form.pageNumber"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Pages from '@/components/pages'
import { messagePlanDetail, messagePlanSendLogGetList, cancelSend } from '@/api'
export default {
  components: {
    Pages,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [
        {
          id: 0,
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          id: 1,

          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄',
        },
        {
          id: 2,

          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
        },
        {
          id: 3,

          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄',
        },
      ],
      total: 0,
      data: {},
      loading: false,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      tableLoading: false,
    }
  },
  mounted() {
    console.log(this.row)
    this.messagePlanDetail()
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.tableLoading = true
      this.form.data.planId = this.row.planId
      messagePlanSendLogGetList(this.form).then((res) => {
        this.tableLoading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    messagePlanDetail() {
      this.loading = true
      messagePlanDetail({
        data: {
          planId: this.row.planId,
        },
      }).then((res) => {
        this.loading = false
        this.data = { ...res.data }
      })
    },
    back() {
      this.$emit('back')
    },
    cancel() {
      this.$confirm(`是否确定取消发送任务？`)
        .then(() => {
          cancelSend({
            data: {
              planId: this.row.planId,
            },
          }).then((res) => {
            this.messagePlanDetail()
            this.getTableData()
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          })
        })
        .catch(() => {})
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  padding: 20px 0;
  font-weight: bold;
}
</style>
