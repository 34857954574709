<template>
  <div>
    <div v-if="!showDetail">
      <div v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_BIRTHDAYAUTOBLESSING')" class="top">
        <div @click="changeCheck">
          <el-checkbox v-model="isEnable" :true-label="0" disabled></el-checkbox>
        </div>

        <!-- <el-radio v-model="radio" @change="changeCheck" label="1">{{
          str
        }}</el-radio> -->
        <div class="text">启用生日自动祝福</div>
        <el-link type="primary" @click="$router.push('/autoBlessing')">功能设置</el-link>
        <el-tooltip
          class="item"
          effect="dark"
          content="启用生日自动祝福后，到了客户生日，系统将自动把设置好的内容发送给客户(短信)。"
          placement="bottom"
        >
          <i style="margin-left: 10px" class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <page-header @search="search" />
      <div class="table">
        <div class="btn">
          <el-button
            v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_ADDMSGPUSHPLAN')"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="$router.push('/msgPushPlan')"
            >新增</el-button
          >
          <el-button size="small" @click="exportTable">导出</el-button>
          <!-- <el-button size="small" @click="toggleSelection('all')"
            >全选</el-button
          >
          <el-button size="small" @click="toggleSelection()">反选</el-button> -->
        </div>
        <Table
          ref="table"
          :table-data="tableData"
          :form="form"
          :total="total"
          :loading="loading"
          @handleSelectionChange="handleSelectionChange"
          @detail="detail"
          @cancel="cancel"
        />
        <Pages
          :current-page="form.pageNumber"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
    <Detail v-else :row="row" @back="back" />
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import Detail from './detail'
import { messagePlanGetList, cancelSend, getWishPlan } from '@/api'
import { downloadFile } from '@/utils/fileDownload'

export default {
  components: {
    pageHeader,
    Table,
    Pages,
    Detail,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      isEnable: 1,
      showDetail: false,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      row: {},
      radio: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {
    showDetail: function (val) {
      const a = document.querySelector('.el-tabs__header')
      if (val) {
        a.style.display = 'none'
      } else {
        a.style.display = 'block'
      }
    },
  },
  mounted() {
    this.getTableData()
    this.getWishPlan()
  },
  methods: {
    back() {
      this.showDetail = false
      this.getTableData()
    },
    changeCheck() {
      this.$confirm(`是否确定${this.isEnable === 0 ? '禁用' : '启用'}生日自动祝福？`)
        .then(() => {
          cancelSend({
            data: {
              planType: 5,
            },
          }).then((res) => {
            this.getWishPlan()
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          })
        })
        .catch(() => {})
    },
    getWishPlan() {
      getWishPlan({
        data: {
          isAll: 1,
        },
      }).then((res) => {
        if (res.data) {
          this.isEnable = res.data.isCancel
        }
      })
    },
    getTableData() {
      this.loading = true
      this.form.data.type = 1
      messagePlanGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = data
      this.getTableData()
    },
    detail(row) {
      this.showDetail = true
      this.row = { ...row }
    },
    cancel(row) {
      this.$confirm(`是否确定取消发送任务？`)
        .then(() => {
          cancelSend({
            data: {
              planId: row.planId,
            },
          }).then((res) => {
            this.getTableData()
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          })
        })
        .catch(() => {})
    },
    add() {},
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportTable() {
      if (this.multipleSelection.length === 0) {
        this.$message('请至少选择一条数据')
        return
      }
      const data = { ...this.form }
      data.data.isExport = 1
      data.data.ids = []
      this.multipleSelection.forEach((e) => {
        data.data.ids.push(e.planId)
      })
      this.loading = true
      messagePlanGetList(data).then((res) => {
        this.loading = false
        downloadFile(res, '消息推送记录')
      })
    },
  },
}
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #999;
  height: 46px;
  background-color: #f9f9f9;
  padding-right: 20px;
  border: #e9e9e9 1px solid;
  margin-bottom: 15px;
  .text {
    padding: 0 4px;
    font-size: 14px;
  }
}
::v-deep .is-disabled {
  .el-checkbox__inner {
    cursor: pointer !important;
    background-color: #fff !important;
  }
  .is-checked .el-checkbox__inner {
    background-color: #409eff !important;
  }
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
  cursor: pointer !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
</style>
