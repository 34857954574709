<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane v-for="(n, i) in list" :key="i" :label="n.label" :name="n.name">
        <MsgPush v-if="activeName == '1'" />
        <MsgSend v-if="activeName == '2'" />
        <!-- <CallRecord v-if="activeName == '3'" /> -->
      </el-tab-pane>
      <!-- <el-tab-pane label="短信发送记录" name="second"> </el-tab-pane>
      <el-tab-pane label="电话拨打记录" name="third"> </el-tab-pane> -->
    </el-tabs>
    <div v-if="list.length == 0" class="noPower">暂无权限，请联系管理员开通</div>
  </div>
</template>

<script>
// import CallRecord from "@/views/callRecord/index.vue";
import MsgPush from '@/views/msgPush/index.vue'
import MsgSend from '@/views/msgSend/index.vue'
export default {
  components: {
    // CallRecord,
    MsgPush,
    MsgSend,
  },
  data() {
    return {
      activeName: '',
      list1: [
        {
          label: '消息推送',
          nodeCode: 'YY_MARKET_MSGPUSH',
          name: '1',
        },
        {
          label: '短信发送记录',
          nodeCode: 'YY_MARKET_MSGSENDRECORD',
          name: '2',
        },
        // {
        //   label: "电话拨打记录",
        //   nodeCode: "YY_MARKET_TELLRECORD",
        //   name: "3",
        // },
      ],
      list: [],
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  mounted() {
    this.powerList.forEach((e) => {
      this.list1.forEach((v) => {
        if (e.nodeCode === v.nodeCode) {
          this.list.push(v)
        }
      })
    })
    this.activeName = this.list[0] ? this.list[0].name : ''
  },
  methods: {
    handleClick(tab, event) {},
  },
}
</script>

<style lang="less" scoped>
::v-deep .el-tabs {
  .el-tabs__item {
    background-color: #fff;
  }
  .el-tabs__nav {
    .is-active {
      color: #fff;
      background-color: #2861e2;
    }
  }
}
</style>
