<template>
  <el-table
    ref="multipleTable"
    v-loading="loading"
    :data="tableData"
    border
    style="width: 100%"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column label="序号" width="100">
      <template slot-scope="scope">
        <span>{{ scope.$index + 1 + (form.pageNumber - 1) * form.pageSize }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="planTitle" label="发送主题" width="180"> </el-table-column>
    <el-table-column prop="receiverRangeKeyName" label="发送人群" width="180"> </el-table-column>
    <el-table-column prop="receiverNumber" label="触发人数" width="180"> </el-table-column>
    <el-table-column prop="createUserName" label="创建人" width="180"> </el-table-column>
    <el-table-column prop="isDelaySendName" label="发送方式" width="180"> </el-table-column>
    <el-table-column prop="createTime" label="创建时间" width="180"> </el-table-column>
    <el-table-column prop="delaySendTime" label="发送时间" width="180"> </el-table-column>
    <el-table-column prop="statusName" label="发送状态" width="180"> </el-table-column>
    <el-table-column label="操作" width="200">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="detail(scope.row, scope.$index + 1 + (form.pageNumber - 1) * form.pageSize)"
          >详情</el-button
        >
        <el-button
          v-if="!scope.row.isDelaySend == 0 && (scope.row.status == 1 || scope.row.status == 2)"
          type="text"
          size="small"
          @click="cancel(scope.row)"
          >取消发送</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectData: [],
    }
  },
  methods: {
    detail(row, num) {
      this.$emit('detail', { ...row, num })
    },
    cancel(row) {
      this.$emit('cancel', row)
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
        // if (this.selectData.length > 0) {
        //   console.log(this.getArrDifference(this.tableData, this.selectData));
        //   this.getArrDifference(this.tableData, this.selectData).forEach(
        //     (row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     }
        //   );
        //   this.selectData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row, false);
        //   });
        // }
      }
    },
    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped></style>
