<template>
  <div class="main">
    <div v-if="!showDetail">
      <page-header @search="search" />
      <div class="table">
        <div class="btn">
          <el-button size="small" @click="exportTable">导出</el-button>
          <!-- <el-button size="small" @click="toggleSelection('all')"
            >全选</el-button
          >
          <el-button size="small" @click="toggleSelection()">反选</el-button> -->
        </div>
        <Table
          ref="table"
          :table-data="tableData"
          :form="form"
          :total="total"
          :loading="loading"
          @handleSelectionChange="handleSelectionChange"
          @detail="detail"
          @cancel="cancel"
        />
        <Pages
          :total="total"
          :current-page="form.pageNumber"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
    <Detail v-else :row="row" type="send" @back="back" />
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import Detail from '@/views/msgPush/detail'
import { messagePlanGetList, cancelSend } from '@/api'
import { downloadFile } from '@/utils/fileDownload'
export default {
  components: {
    pageHeader,
    Table,
    Pages,
    Detail,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      showDetail: false,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      row: {},
    }
  },
  watch: {
    showDetail: function (val) {
      const a = document.querySelector('.el-tabs__header')
      if (val) {
        a.style.display = 'none'
      } else {
        a.style.display = 'block'
      }
    },
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    back() {
      this.showDetail = false
      this.getTableData()
    },
    detail(row) {
      this.showDetail = true
      this.row = { ...row }
    },
    cancel(row) {
      this.$confirm(`是否确定取消发送任务？`)
        .then(() => {
          cancelSend({
            data: {
              planId: row.planId,
            },
          }).then((res) => {
            this.getTableData()
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          })
        })
        .catch(() => {})
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data }
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      this.form.data.type = 2
      messagePlanGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      console.log('当前选择', val)
      this.multipleSelection = val
    },
    exportTable() {
      if (this.multipleSelection.length === 0) {
        this.$message('请至少选择一条数据')
        return
      }
      const data = { ...this.form }
      data.data.isExport = 1
      data.data.ids = []
      this.multipleSelection.forEach((e) => {
        data.data.ids.push(e.planId)
      })
      this.loading = true
      messagePlanGetList(data).then((res) => {
        this.loading = false
        downloadFile(res, '短信发送记录')
      })
    },
  },
}
</script>
<style lang="less" scoped></style>
