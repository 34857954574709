<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="发送状态">
        <el-select
          v-model="form.status"
          size="small"
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option label="待执行" value="2"> </el-option>
          <el-option label="已执行" value="3"> </el-option>
          <el-option label="已取消" value="4"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="发送主题">
        <el-input
          v-model="form.planTitle"
          size="small"
          style="width: 160px"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item>

      <el-form-item label="发送方式">
        <el-select
          v-model="form.isDelaySend"
          size="small"
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option label="定时发送" value="1"> </el-option>
          <el-option label="立即发送" value="0"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发送人群">
        <el-select
          v-model="form.receiverRangeKey"
          size="small"
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option
            v-for="(n, i) in customerCate"
            :key="i"
            :label="n.dictionaryName"
            :value="n.dictionaryValue"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-link type="primary" style="margin-right: 10px" @click="checkFold">{{
          fold ? "收起" : "展开"
        }}</el-link>
        <el-button type="primary" size="small" @click="onSubmit"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
      <template>
        <div
          v-show="fold"
          :class="fold ? 'fold' : 'unfold'"
          style="
            padding: 20px;
            border-radius: 4px;
            box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
          "
        >
          <el-form-item label="创建人">
            <el-input
              v-model="form.createUserName"
              size="small"
              style="width: 160px"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="form.time"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 340px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发送时间">
            <el-date-picker
              v-model="form.time1"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 340px"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { queryChildsById } from "@/api";
import moment from "moment";
export default {
  name: "PageHeader",
  components: {},
  data() {
    return {
      form: {},
      fold: false,
      customerCate: [],
    };
  },
  mounted() {
    this.queryChildsById();
  },
  methods: {
    queryChildsById() {
      queryChildsById({
        data: { key: "message_scope" },
      }).then((res) => {
        this.customerCate = res.data.childs;
      });
    },
    onSubmit() {
      const data = { ...this.form };
      if (data.time) {
        data.createTimeStart = moment(data.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        data.createTimeEnd = moment(data.time[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      if (data.time1) {
        data.sendTimeStart = moment(data.time1[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        data.sendTimeEnd = moment(data.time1[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      delete data.time;
      delete data.time1;
      this.$emit("search", data);
    },
    reset() {
      this.form = {};
      this.$emit("search", {});
    },

    checkFold() {
      this.fold = !this.fold;
    },
  },
};
</script>
<style lang="less" scoped>
</style>
